<template>
	<div class="recomeSerach">
		<Else></Else>
		<div class="recomeSerach-con">
			<div class="recomeSerach-middle">
				<div class="title">AI商标注册成功率评估</div>
				<div class="recomeSerach-inp">
					<input type="text" class="input1" v-model="brandName" placeholder="请输入商标名称">
					<!-- <input type="text" v-model="productName" class="input2" placeholder="请输入商品名称或服务" @blur="newlose" >
					 -->
					<div class="recome-guild-pickguild">
						<input type="text" class="input2" placeholder="请输入商品名称或服务" v-model="productName" @blur="newlose">
						<div class="recome-guild-select" v-if="newIs">
							<div class="recome-guild-select-div" :title="item" v-for="(item,index) in newData" :key="index" @click="addOne(item)"
							 v-show="newData.length !== 0">
								{{item}}
							</div>
							<div class="recome-guild-select-div2" v-show="newData.length == 0">暂无匹配关键词</div>
						</div>
					</div>
					<span class="span1" @click="to">AI评估</span>
				</div>
				<div class="title-tip">本服务为社会公众提供商标相关信息查询，本站不负责其及时性、准确性判断,仅供参考，不具有法律效力。</div>
			</div>
		</div>

	</div>

</template>

<script>
	import Else from '../ui/else.vue';
	const delay = (function() {
		let timer = 0;
		return function(callback, ms) {
			clearTimeout(timer);
			timer = setTimeout(callback, ms);
		};
	})();
	export default {
		name: 'recomeSerach',
		data() {
			return {
				brandName: '',
				productName: '',
				newIs: false,
				newData: [],
			}
		},
		components: {
			Else
		},
		watch: {
			'productName'(newValue) {
				// console.log(this.jobArr)
				if (newValue == "") {
					this.newIs = false;
				} else {
					this.newIs = true;
					// console.log(newValue)
					for (var i = 0; i < this.newData.length; i++) {
						if (this.newData[i] == newValue) {
							this.newIs = false;
						}
					}
					delay(() => {
						this.fetchData();
					}, 400);
				}
			}
		},
		methods: {
			addOne(val){
				this.productName = val;
				this.newlose()
			},
			newlose() {
				setTimeout(() => {
					this.newIs = false;
				}, 500);
			},
			async fetchData(val) {
				this.$http.post(this.GLOBAL.new_url + '/trademark/service/es/trademark/regiest/search/completion/suggest', {
					keyword: this.productName,
					page: 1,
					size: 100
				}, {
					headers: {
						AuthToken: this.$cookie.getCookie('token'),
						'X-Request-Origin': 'xcx'
					}
				}).then(res => {
					// console.log(res);
					if (res.data.code == "200") {
						this.newData = res.data.data.wvNameList;
					}
				})
			},
			tip(type, msg) {
				return this.$message({
					type: type,
					message: msg,
					showClose: true,
					duration: 1500,
				});
			},
			to() {
				let reg = /^[0-9]*$/;
				let reg2 = /[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/im;
				let reg3 = /^[\u4e00-\u9fa5]+$/
				if (this.brandName == '') {
					this.tip('error', "请输入商标名称")
					return false
				} else if (reg.test(this.brandName)) {
					this.tip('error', "请输入汉字或字母")
					return false
				} else if (reg2.test(this.brandName)) {
					this.tip('error', "请输入正确的商标名称(不包括特殊字符)")
					return false
				} else if (this.productName == "") {
					this.tip('error', "请输入商品名称或服务")
					return false
				} else {
					this.$router.push({
						path: '/recome',
						query: {
							brandName: this.brandName,
							productName: this.productName
						}
					})
				}
			}
		},
		mounted() {
			window.parent.postMessage({
					data: 715,
				},
				"*"
			);
		}
	}
</script>

<style scoped>
	html,
	body,
	#app {
		margin: 0;
		padding: 0;
		height: 100%;
	}

	.recomeSerach-con {
		width: 100vw;
		height: 100vh;
		background: url('../../../static/images/bj77.png') 100% no-repeat;
		background-size: cover;
		position: relative;
	}

	.recomeSerach-middle {
		width: 1050px;
		height: 320px;
		/* border:1px solid red; */
		position: absolute;
		top: 45%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	.title {
		width: 1050px;
		color: #ffffff;
		font-size: 34px;
		text-align: center;
		margin-bottom: 50px;
	}

	input::-webkit-input-placeholder {
		/* Chrome/Opera/Safari */
		color: rgb(220, 220, 220);
		font-weight: 300;
	}

	.recomeSerach-inp {
		display: flex;
	}

	.recomeSerach-inp>input,
	.recome-guild-pickguild>input {
		outline: none;
		vertical-align: middle;
		height: 58px;
		padding-left: 5px;
		text-indent: 20px;
		letter-spacing: 2px;
		font: 20px "Arial", "Microsoft YaHei", "黑体", "宋体", sans-serif;
	}

	.input1 {
		width: 392px;
		margin-right: 3px;
	}

	.input2 {
		width: 490px;
	}

	.span1 {
		width: 145px;
		height: 62px;
		vertical-align: middle;
		display: inline-block;
		background: #ff8328;
		border-radius: 0px 5px 5px 0px;
		font-size: 24px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 60px;
		text-align: center;
		cursor: pointer;
	}

	.title-tip {
		width: 1050px;
		text-align: center;
		font-size: 14px;
		margin-top: 60px;
		color: #FFFFFF;
		font-family: Microsoft YaHei;
	}

	/* 	.recome-guild-pickguild,
	.recome-brand>input {
		margin-left: 40px;
	} */

	.recome-guild-pickguild-example {
		font-size: 12px;
		margin-top: 12px;
		font-weight: 400;
		color: rgba(0, 0, 0, 0.45);
	}

	.recome-guild-select {
		position: absolute;
		width: 500px;
		background: white;
		height: 300px;
		overflow-y: scroll;
	}

	.recome-guild-pickguild-div>span {
		display: inline-block;
		width: 60px;
		height: 36px;
		border-radius: 4px;
		border: 1px solid rgba(85, 84, 91, 0.1);
		vertical-align: middle;
		font-size: 22px;
		text-align: center;
		line-height: 36px;
		color: #383842;
		cursor: pointer;
	}

	.recome-guild-select::-webkit-scrollbar {
		width: 8px;
		height: 8px;
	}

	.recome-guild-select::-webkit-scrollbar-thumb {
		border-radius: 5px;
		box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
	}

	.recome-guild-select::-webkit-scrollbar-track {
		opacity: 0;
	}

	.recome-guild-select-div {
		height: 50px;
		border-bottom: 1px solid #D6D6D6;
		line-height: 50px;
		font-size: 16px;
		font-weight: 400;
		cursor: pointer;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		text-indent: 2em;
	}

	.recome-guild-select-div2 {
		height: 100%;
		text-align: center;
		color: #FF5E00;
		font-size: 16px;
		line-height: 300px;
	}
</style>
